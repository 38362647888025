.modalBackground {
    width: 40vw;
    height: 80vh;
    background-color: rgba(0, 0, 0, 0);
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
  }
  
  .modalContainer {
    width: 250px;
    height: 210px;
    border-radius: 12px;
    background-color: white;
    box-shadow: rgba(0, 0, 0) 0px 5px 15px;
    display: flex;
    flex-direction: column;
    padding: 25px;
  }
  
  .modalContainer .title {
    display: inline-block;
    text-align: center;
    margin-top: 10px;
  }
  
  .titleCloseBtn {
    display: flex;
    justify-content: flex-end;
  }
  
  .titleCloseBtn button {
    background-color: transparent;
    border: none;
    font-size: 15px;
    cursor: pointer;
  }
  
  .modalContainer .body {
    flex: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    text-align: center;
  }
  
  .modalContainer .footer {
    flex: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modalContainer .footer button {
    width: 100px;
    height: 30px;
    margin: 10px;
    border: none;
    background-color: cornflowerblue;
    color: white;
    border-radius: 8px;
    font-size: 20px;
    cursor: pointer;
  }
  
  #cancelBtn {
    background-color: crimson;
  }