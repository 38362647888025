.newPost-header{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0.5rem;
  border-bottom: 1px solid #ccc;

}
.title{
  font-size: 2rem;
  font-weight: bold;
  align-self: flex-start;
  align-content: flex-start;
}
.newPost-body{
  align-items: flex-start;
  display: flex;
  align-self: flex-start;
  flex-direction: column;
  justify-content: center;
  border-bottom: 1px solid #ccc;
}
.newPost-body-titulo{
  align-self: flex-start;
  align-content: center;
}
.newPost-body-categoria{
  justify-content: space-between;
  flex-direction: row;
  border: 2px solid #ccc;


}
.newPost-body-boton{
  align-self: flex-start;
  align-content: space-between; 

}

.card-lateral{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  border-bottom: 1px solid #ccc;
}
.headertextcard{
  font-size:18px;
  color: #ccc;
}

.input-title{
  width: 300%;
  height: 50px;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
  background-color: #ffffff;
  padding: 0.5rem;
  font-size: 12px;
}
textarea {
  width: 300%;
  height: 300px;
  padding: 12px 20px;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
  background-color: #f8f8f8;
  font-size: 16px;
  resize: none;
}

select{
  width: 50%;
  height: 45px;
  padding: 12px 20px;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
  background-color: #f8f8f8;
  font-size: 16px;
  resize: none;
}

article {
  float: left;
  padding: 10px;
  width: 70%;
  height: 300px; /* only for demonstration, should be removed */
}

section::after {
  content: "";
  display: table;
  clear: both;
}