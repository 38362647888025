.navbar{
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
}
.navbar ul{
    margin: 0;
    padding: 0;
}
.navbar ul li{
    list-style: none;
    display: inline-block;
}
.navbar ul li a{
    text-decoration: none;
    display: block;
    font-size: 20px;
    text-transform: capitalize;
    font-weight: 400;
    color: #565673;
    padding: 0 10px;
}
.search form{
    display: flex;
    margin: 0 10px;
}

.searchInput{
    border: 0;
    width: 0;
    transition: width 0.1s ease-in;
}

.searchInput.active{
    width: 120px;
}

.searchInput:focus{
    outline: none;
}

.searchIcon{
    cursor: pointer;
    align-self: center;
    align-content: center;
}