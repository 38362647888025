.home-header {
  text-align: center;
}

.home-header h2 {
  color: #0080ff;
  font-size: 2rem;
}

.home-header h1 {
  font-size: 3rem;
  color: #0f52ba;
  margin-bottom: 1rem;
}

.home-header h1 span {
  color: #b0c4de;
}

.home-header p {
  color: #a9a9a9;
  font-weight: 500;
}

.searchInput{
  border: 0;
  width: 0;
  transition: width 0.1s ease-in;
}

.searchInput.active{
  width: 120px;
}

.searchInput:focus{
  outline: none;
}

.searchIcon{
  cursor: pointer;
  align-self: center;
  align-content: center;
}